import React from "react";
import styled from "@emotion/styled";
import PageTitle from "../../components/PageTitle";
import Spacer from "../../components/Spacer";
import TextContainer from "../../components/TextContainer";
import { colors } from "../../styles/colors";

const OurMission = () => {
  const description = `The mission of the Benton County Sheriff Foundation is to provide both volunteer and financial support for a safe community by creating partnerships and improving resources and programs to members of law enforcement and the community by consistently identifying and securing charitable public and private contributions.`;
  return (
    <PageWrapper
      style={{ backgroundColor: colors.green.light, height: "100%" }}
    >
      <Spacer space={6.5} />
      <PageTitle title="Benton Country Sheriff Foundation Washington State" />
      <TextContainer title="Our Mission" description={description} />
    </PageWrapper>
  );
};
export default OurMission;

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
`;

import React, { ReactNode, useState } from "react";
import { Link } from "react-router-dom";
import { colors } from "../styles/colors";

type StyledLinkProps = {
  to: string;
  text: string;
  subLink?: boolean;
  image?: string;
  children?: ReactNode;
};
const StyledLink = ({
  to,
  text,
  image,
  children,
  subLink,
}: StyledLinkProps) => {
  const [hoveredStyle, setHoveredStyle] = useState<boolean>(false);
  const getHoverColor = () => {
    if (hoveredStyle && subLink) {
      return colors.green.dark;
    } else if (hoveredStyle) {
      return colors.green.normal;
    } else if (subLink) {
      return colors.green.normal;
    } else {
      return colors.green.dark;
    }
  };
  return (
    <Link
      style={{
        fontSize: subLink ? "16px" : "25px",
        color: colors.white.dark,
        textDecoration: "none",
        display: "flex",
        alignItems: "center",
        gap: ".5rem",
        backgroundColor: getHoverColor(),
        borderRadius: "5px",
        padding: ".5rem",
      }}
      to={to}
      onMouseEnter={() => {
        setHoveredStyle(true);
      }}
      onMouseLeave={() => {
        setHoveredStyle(false);
      }}
    >
      {image && <img src={image} width="60" height="60" alt={text} />}
      {text}
      {children}
    </Link>
  );
};

export default StyledLink;

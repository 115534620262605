import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Button } from "@mui/material";
import { auth } from "../firebase/firebase.utils";
import SignIn from "./SignIn";
import { ThemeProvider } from "@emotion/react";
import { defaultTheme } from "../styles/themes";
import BlogsTable from "./Blog/BlogsTable";
import { Snackbar } from "@material-ui/core";
import Alert from "../components/Alert";
import { colors } from "../styles/colors";
import PageTitle from "../components/PageTitle";

export type AlertProps = {
  open?: boolean;
  color: string;
  message: string;
};

const AdminDash = () => {
  const [userLoggedIn, setUserLoggedIn] = useState<boolean>();
  const [isAlertOpen, setIsAlertOpen] = useState<AlertProps>({
    open: false,
    color: "",
    message: "",
  });
  const authentication = auth.app.auth();
  const handleSignOut = () => {
    authentication
      .signOut()
      .then(() => {
        // Sign-out successful.
      })
      .catch((error) => {
        // An error happened.
      });
  };
  authentication.onAuthStateChanged(() => {
    if (authentication.currentUser) {
      setUserLoggedIn(true);
    } else {
      setUserLoggedIn(false);
    }
  });
  useEffect(() => {
    userLoggedIn &&
      setIsAlertOpen({
        open: true,
        color: colors.green.normal,
        message: "Successfully signed in",
      });
  }, [userLoggedIn]);

  return (
    <ThemeProvider theme={defaultTheme}>
      <PageWrapper>
        <div style={{ marginTop: "8rem", width: "100%" }}>
          <PageTitle title={userLoggedIn ? "Admin Dashboard" : "Sign In"} />
          {userLoggedIn && (
            <Button
              onClick={() => {
                handleSignOut();
                userLoggedIn
                  ? setIsAlertOpen({
                      open: true,
                      color: colors.green.normal,
                      message: "Signed out successfully",
                    })
                  : setIsAlertOpen({
                      open: true,
                      color: colors.red.normal,
                      message: "Could not sign out",
                    });
              }}
            >
              Sign Out
            </Button>
          )}
          <DashBoardContainer>
            {userLoggedIn ? (
              <>
                <BlogsTable />
              </>
            ) : (
              <SignIn />
            )}
          </DashBoardContainer>
        </div>
        <Snackbar
          anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
          open={isAlertOpen.open}
          autoHideDuration={3000}
          onClose={() =>
            setIsAlertOpen({
              open: false,
              color: "",
              message: "",
            })
          }
        >
          <Alert message={isAlertOpen.message} color={isAlertOpen.color} />
        </Snackbar>
      </PageWrapper>
    </ThemeProvider>
  );
};
export default AdminDash;

const DashBoardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

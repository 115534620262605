import React, { useState } from "react";
import styled from "@emotion/styled";
import { Drawer } from "@material-ui/core";
import logo from "../resources/logo.png";
import StyledLink from "./StyledLink";
import useWindowDimensions from "../customHooks/UseWindowDimensions";
import MenuIcon from "@mui/icons-material/Menu";
import { colors } from "../styles/colors";

const Header = () => {
  const { width } = useWindowDimensions();
  const [isDrawer, setIsDrawer] = useState<boolean>(false);
  const [isHomeSubLinks, setIsHomeSubLinks] = useState<boolean>(false);
  const [isAboutSubLinks, setIsAboutSubLinks] = useState<boolean>(false);
  return (
    <StyledBar>
      {width <= 750 ? (
        <MobileNav>
          <StyledLink to="/" text="" image={logo} />
          <div style={{ width: "100%" }} />
          <Hamburger>
            <MenuIcon
              onClick={() => {
                setIsDrawer(!isDrawer);
              }}
              style={{ height: "50px", width: "50px" }}
            />
          </Hamburger>
          <Drawer
            open={isDrawer}
            anchor="right"
            onClose={() => setIsDrawer(false)}
          >
            <LinkContainer>
              <StyledLink to="/" text="Home" />
              <StyledLink to="/our_mission" text="Our Mission" />
              <StyledLink to="/our_purpose" text="Our Purpose" />
              <StyledLink
                to="/current_initiatives"
                text="Current Initiatives"
              />
              <StyledLink to="/help" text="How can I help?" />
              <StyledLink to="/blog" text="Blog" />
              <StyledLink to="/about" text="About the Foundation" />
              <StyledLink to="/officers" text="Officers" />
              {/* <StyledLink to="/grants" text="Grants/Contributions" /> */}
              <StyledLink to="/docs" text="Foundation Documents" />
              <StyledLink to="/donations" text="Donations" />
            </LinkContainer>
          </Drawer>
        </MobileNav>
      ) : (
        <Nav>
          <div style={{ width: "inherit" }}>
            <StyledLink
              to="/"
              text="Benton County Sheriff Foundation"
              image={logo}
            />
          </div>
          <div style={{ width: "100%" }} />
          <div
            onMouseEnter={() => setIsHomeSubLinks(true)}
            onMouseLeave={() => setIsHomeSubLinks(false)}
          >
            <StyledLink to="/" text="HOME" />
            {isHomeSubLinks && (
              <AnchorContainer>
                <StyledLink to="/" text="Welcome" subLink />
                <StyledLink to="/our_mission" text="Our Mission" subLink />
                <StyledLink to="/our_purpose" text="Our Purpose" subLink />
                <StyledLink
                  to="/current_initiatives"
                  text="Current Initiatives"
                  subLink
                />
                <StyledLink to="/help" text="How can I help?" subLink />
              </AnchorContainer>
            )}
          </div>
          <div
            onMouseEnter={() => setIsAboutSubLinks(true)}
            onMouseLeave={() => setIsAboutSubLinks(false)}
          >
            <StyledLink to="/about" text="ABOUT" />
            {isAboutSubLinks && (
              <AnchorContainer>
                <StyledLink to="/about" text="About the Foundation" subLink />
                <StyledLink to="/officers" text="Officers" subLink />
                {/* <StyledLink to="/grants" text="Grants/Contributions" subLink /> */}
                <StyledLink to="/docs" text="Foundation Documents" subLink />
              </AnchorContainer>
            )}
          </div>
          <StyledLink to="/blog" text="BLOG" />
          <StyledLink to="/donations" text="DONATIONS" />
        </Nav>
      )}
    </StyledBar>
  );
};
export default Header;

const StyledBar = styled.div`
  display: flex;
  width: 100%;
  background-color: ${colors.green.dark};
  position: fixed;
  box-shadow: 0px 0px 7px 0px #000000;
`;
const Nav = styled.div`
  width: 100%;
  padding: 1rem;
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;
const MobileNav = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;
const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
  padding: 0.2rem;
`;
const AnchorContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  position: absolute;
  background-color: ${colors.green.normal};
  border: 1px solid ${colors.green.light};
  border-radius: 5px;
  box-shadow: 0px 0px 7px 0px #000000;
  padding: 1rem;
`;
const Hamburger = styled.div`
  color: ${colors.white.dark};
  cursor: pointer;
  border-radius: 5px;
  margin-right: 1rem;
  :hover {
    background-color: ${colors.green.normal};
  }
`;

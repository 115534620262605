import React from "react";
import styled from "@emotion/styled";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { colors } from "../../styles/colors";
//@ts-expect-error
import certificate from "../../resources/Certificate.pdf";
//@ts-expect-error
import articlesOfIncorporation from "../../resources/ArticlesofIncorporationSigned.pdf";
import PageTitle from "../../components/PageTitle";
import { PageWrapper } from "../Home/OurMission";
import Spacer from "../../components/Spacer";

const Docs = () => {
  return (
    <PageWrapper style={{ backgroundColor: colors.green.light }}>
      <Spacer space={6.5} />
      <PageTitle title="Sheriff Foundation Documents" />
      <DocumentsContainer>
        <Document
          documentTitle="Articles of Incorporation"
          document={articlesOfIncorporation}
        />
        <Document
          documentTitle="Articles of Incorporation Certificate"
          document={certificate}
        />
      </DocumentsContainer>
    </PageWrapper>
  );
};
export default Docs;

const DocumentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: #666464;
  justify-content: baseline;
  align-items: center;
  gap: 2rem;
  margin: 3rem;
`;

type DocumentProps = {
  documentTitle: string;
  document: any;
};
const Document = ({ documentTitle, document }: DocumentProps) => {
  return (
    <DocumentContainer
      onClick={() => {
        window.open(document);
      }}
    >
      <DocumentTitle>{documentTitle}</DocumentTitle>
      <PictureAsPdfIcon />
    </DocumentContainer>
  );
};

const DocumentContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  box-shadow: 0px 0px 7px 0px #5c5c5c;
  border-radius: 5px;
  padding: 1rem;
  &:hover {
    cursor: pointer;
    background-color: ${colors.green.normal};
    color: ${colors.white.dark};
  }
`;
const DocumentTitle = styled.div`
  font-size: 24px;
`;

import React from "react";
import useWindowDimensions from "../customHooks/UseWindowDimensions";

const Spacer = ({ space }: { space: number }) => {
  const { width } = useWindowDimensions();
  return (
    <div style={{ marginTop: width <= 750 ? `${space}rem` : `${space}rem` }} />
  );
};
export default Spacer;

import React from "react";
import styled from "@emotion/styled";
import { AlertProps } from "../views/AdminDash";
const Alert = ({ message, color }: AlertProps) => {
  return (
    <AlertContainer style={{ backgroundColor: color }}>
      <div>{message}</div>
    </AlertContainer>
  );
};
export default Alert;

const AlertContainer = styled.div`
  color: white;
  display: flex;
  align-items: center;
  border-radius: 5px;
  padding: 1rem;
  gap: 1rem;
`;

import React from "react";
import AccessibilityNewIcon from "@mui/icons-material/AccessibilityNew";
import styled from "@emotion/styled";
import { colors } from "../styles/colors";
import pink from "../resources/benton_pink_20222.jpg";
import autismPatch from "../resources/autismPatch.jpg";
import PageTitle from "../components/PageTitle";
import { PageWrapper } from "./Home/OurMission";
import Spacer from "../components/Spacer";

export const goToDonationPage = (url: string) => {
  window.open(url, "_blank");
};
const Donation = () => {
  return (
    <PageWrapper style={{ backgroundColor: colors.green.light }}>
      <Spacer space={6.5} />
      <PageTitle title="Benton Country Sheriff Foundation Washington State" />
      <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
        <DonationContainer
          onClick={() =>
            goToDonationPage(
              "https://www.paypal.com/donate/?hosted_button_id=C8M9SVBNDPPVL"
            )
          }
        >
          <span>General Donations to BCSF</span>
          <AccessibilityNewIcon />
        </DonationContainer>
      </div>
    </PageWrapper>
  );
};
export default Donation;
type donationContainerProps = {
  color?: string;
  hoverColor?: string;
};
export const DonationContainer = styled.div<donationContainerProps>`
  display: flex;
  align-items: center;
  gap: 1rem;
  border-radius: 5px;
  background-color: ${(props) =>
    props.color ? props.color : colors.green.dark};
  padding: 1rem;
  justify-content: space-between;
  color: ${colors.white.dark};
  &:hover {
    cursor: pointer;
    color: ${colors.white.dark};
    background-color: ${(props) =>
      props.hoverColor ? props.hoverColor : colors.green.normal};
  }
`;

import React, { ReactNode } from "react";
import styled from "@emotion/styled";
import { colors } from "../styles/colors";

type TextContainerProps = {
  title: string;
  titleColor?: string;
  children?: ReactNode;
  description: string;
  photos?: ReactNode;
};
const TextContainer = ({
  title,
  titleColor,
  children,
  description,
  photos,
}: TextContainerProps) => {
  return (
    <SectionContainer>
      <SectionBody>
        <SectionChildren>
          <h3 style={{ color: titleColor ? titleColor : "#666464" }}>
            {title}
          </h3>
          {children}
        </SectionChildren>
        {description !== "" && <Description>{description}</Description>}
        <div
          style={{ display: "flex", justifyContent: "center", padding: "1rem" }}
        >
          {photos}
        </div>
      </SectionBody>
    </SectionContainer>
  );
};
export default TextContainer;

const SectionContainer = styled.div`
  color: #666464;
  margin: 1rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  border: 1px solid ${colors.green.dark};
  border-radius: 5px;
  box-shadow: 0px 0px 7px 0px #000000;
  padding: 1rem 1rem 2rem 1rem;
`;

const SectionBody = styled.div`
  font-family: sans-serif;
  text-align: left;
  white-space: pre-line;
`;

const SectionChildren = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
`;
export const Description = styled.div`
  display: flex;
  /* background-color: ${colors.white.dark}; */
  border-radius: 5px;
  padding: 1rem;
  /* box-shadow: 0px 0px 7px 0px ${colors.green.normal}; */
`;

import React from "react";
import DonateButton from "../../components/DonateButton";
import PageTitle from "../../components/PageTitle";
import Spacer from "../../components/Spacer";
import TextContainer from "../../components/TextContainer";
import { colors } from "../../styles/colors";

const Help = () => {
  const description = `If you would like to add your support to the Benton County Sheriff Foundation, please visit our “DONATE” page to make a tax-deductible donation.
    
  Donations are deductible under Section 501(c)(3) of the Internal Revenue Code and serve as a vehicle for tax exempt contributions and grants from individuals, businesses, and philanthropies.
  
  Contributions shall be used for programs such as: educational support, technology and equipment, and communication needs not provided for in current budgets; providing both emergency and personal assistance to persons in need in the community; and recognition for law enforcement and members of the community.
    `;
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        backgroundColor: colors.green.light,
        height: "100%",
      }}
    >
      <Spacer space={6.5} />
      <PageTitle title="Benton Country Sheriff Foundation Washington State" />
      <div style={{ alignSelf: "flex-end", marginRight: "1rem" }}>
        <DonateButton />
      </div>
      <TextContainer title="How can I help?" description={description} />
    </div>
  );
};
export default Help;

import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import About from "./views/About/About";
import Docs from "./views/About/Docs";
import Grants from "./views/About/Grants";
import Officers from "./views/About/Officers";
import Blog from "./views/Blog/Blog";
import Donation from "./views/Donations";
import Home from "./views/Home/Home";
import AdminDash from "./views/AdminDash";
import Help from "./views/Home/Help";
import OurPurpose from "./views/Home/OurPurpose";
import OurMission from "./views/Home/OurMission";
import CurrentInitiatives from "./views/Home/CurrentInitiatives";
import Header from "./components/Header";

export default function Router() {
  return (
    <BrowserRouter>
      <Header />
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/our_mission">
          <OurMission />
        </Route>
        <Route exact path="/our_purpose">
          <OurPurpose />
        </Route>
        <Route exact path="/current_initiatives">
          <CurrentInitiatives />
        </Route>
        <Route exact path="/help">
          <Help />
        </Route>
        <Route path="/about">
          <About />
        </Route>
        <Route path="/officers">
          <Officers />
        </Route>
        <Route path="/grants">
          <Grants />
        </Route>
        <Route path="/docs">
          <Docs />
        </Route>
        <Route path="/blog">
          <Blog />
        </Route>
        <Route path="/donations">
          <Donation />
        </Route>
        <Route path="/admin">
          <AdminDash />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

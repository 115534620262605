import { useEffect, useState } from "react";
import MaterialTable from "material-table";
import { firestore } from "../../firebase/firebase.utils";
import { DeleteOutlineOutlined, CreateOutlined, Add } from "@material-ui/icons";
import { ThemeProvider } from "@material-ui/core";
import { blog } from "./BlogConfig";
import ManageBlog from "./ManageBlog";
import styled from "@emotion/styled";
import { defaultTheme } from "../../styles/themes";

const BlogsTable = () => {
  const [blogs, setBlogs] = useState<blog[]>();
  const [currentBlog, setCurrentBlog] = useState<blog>({
    id: "",
    title: "",
    description: "",
    image: "",
  });
  const [openBlogModal, setOpenBlogModal] = useState<boolean>(false);
  const [isAdd, setIsAdd] = useState<boolean>(false);

  useEffect(() => {
    firestore.collection("blogs").onSnapshot((snapshot) => {
      setBlogs(
        //@ts-ignore
        snapshot.docs.map((doc) => {
          return { id: doc.id, ...doc.data() };
        })
      );
    });
  }, []);

  const firestoreDb = firestore.app.firestore();

  async function deletePost(id: string) {
    const docRef = await firestoreDb.collection("blogs").doc(id).delete();
  }

  const columns: { title: string; field: string }[] = [
    { title: "Title", field: "title" },
    { title: "Description", field: "description" },
  ];

  return (
    <ThemeProvider theme={defaultTheme}>
      <TableContainer>
        <MaterialTable
          title={""}
          columns={columns}
          //@ts-ignore
          data={blogs?.map((post) => {
            return {
              id: post.id,
              title: post.title,
              description: post.description,
              image: post.image,
            };
          })}
          options={{
            paging: false,
            maxBodyHeight: "Calc(100VH - 225px)",
            rowStyle: (rowData: { tableData: { id: number } }) => ({
              backgroundColor: rowData.tableData.id % 2 ? "#EEE" : "#FFF",
            }),
          }}
          actions={[
            {
              icon: DeleteOutlineOutlined,
              tooltip: "Delete Post",
              onClick: (_event: any, rowData: any) => {
                deletePost(rowData.id);
              },
            },
            {
              icon: CreateOutlined,
              tooltip: "Edit Post",
              onClick: (_event: any, rowData: any) => {
                setCurrentBlog(rowData);
                setOpenBlogModal(true);
                setIsAdd(false);
              },
            },
            {
              icon: Add,
              tooltip: "Create Post",
              isFreeAction: true,
              // position: "toolbar",
              onClick: () => {
                setOpenBlogModal(true);
                setIsAdd(true);
              },
            },
          ]}
        />
        <ManageBlog
          isAdd={isAdd}
          open={openBlogModal}
          setOpen={setOpenBlogModal}
          blogObject={currentBlog}
        />
      </TableContainer>
    </ThemeProvider>
  );
};

export default BlogsTable;
const TableContainer = styled.div`
  margin: 2rem;
`;

import styled from "@emotion/styled";
import React from "react";
import TextContainer from "../../components/TextContainer";
import tom from "../../resources/tom.jpg";
import bcs from "../../resources/BCS.jpg";
import useWindowDimensions from "../../customHooks/UseWindowDimensions";
import { colors } from "../../styles/colors";
import PageTitle from "../../components/PageTitle";
import Spacer from "../../components/Spacer";

const Home = () => {
  const description = `
          Welcome to the Benton County Sheriff Foundation. The Sheriff’s Foundation was created to provide both volunteer and financial resources, not otherwise available from the county, to strengthen the services provided, promoting excellence in the Sheriff’s Office, and improving public safety in the community.

          We are at a time in history when, more than ever, those that serve our community need our encouragement and support. With the challenges surrounding the current environment of law enforcement, racial tensions, political division, and economic hardships many suffer, we know the Foundation has a continuing role to play in building relationships between the Sheriff’s Office and the community and fill the gap in resources and support to encourage a stronger and safer community.
          
          The Benton County Sheriff’s Office is comprised of very talented, skilled, experienced, and uniquely qualified individuals focused on community and making Benton County a safer place to work, live and play. As members of the Benton County Sheriff Foundation, we are humbled and honored to play a role, in the community, supporting the hard-working dedicated officers that serve.
          
          The Benton County Sheriff Foundation relies on support from individuals, local businesses, and the community to fulfill our mission. Working together we can strengthen our efforts to serve one another and build a safer community.
        `;
  const sheriffDescription = `
          Sheriff Croskrey was appointed as the Benton County Sheriff in 2021. His primary focus has been on improving employee wellness, communicating the importance of teamwork, and serving with honor and integrity. Sheriff Croskrey believes that improving employee morale will have a positive impact in the service our deputies provide to our community.
        
          In addition, Sheriff Croskrey is passionate that working in collaboration with the community and our local law enforcement partners is vital to the overall success of the Benton County Sheriff Office. It is his goal to continue to provide the highest quality public safety to the great citizens of Benton County.
          
          Sheriff Croskrey is dedicated to the success of the Benton County Sheriff’s Office and to the community the sheriff office serves. The Benton County Sheriff’s Office Foundation is a great resource that allows our office to aid those in need and create a safer community.
  `;
  const { width } = useWindowDimensions();
  return (
    <PageWrapper>
      <Overlay>
        <Spacer space={8.5} />
        <PageTitle
          title="Benton Country Sheriff Foundation Washington State"
          titleColor={colors.white.dark}
        />
        <SectionTitle>Welcome!</SectionTitle>
        <BasicContainer>{description}</BasicContainer>
        <SectionTitle>Sheriff Croskrey</SectionTitle>
        <BasicContainer>
          <SheriffContainer width={width}>
            <img src={tom} alt="" height="80%" width="35%" />
            {sheriffDescription}
          </SheriffContainer>
        </BasicContainer>
      </Overlay>
    </PageWrapper>
  );
};
export default Home;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-image: url(${bcs});
  background-repeat: no-repeat;
  background-size: cover;
`;
const SectionTitle = styled.h1`
  color: ${colors.white.dark};
  text-align: center;
  margin-top: 1rem;
`;
export type WidthProps = {
  width: number;
};
const SheriffContainer = styled.div<WidthProps>`
  display: flex;
  color: ${colors.white.dark};
  font-size: 18px;
  gap: 0.5rem;
  flex-direction: ${(props) => (props.width <= 750 ? "column" : "row")};
  align-items: center;
`;
const BasicContainer = styled.div`
  margin: 1rem;
  display: flex;
  border-radius: "5px";
  border: 1px solid ${colors.green.light};
  color: ${colors.white.dark};
  padding: 1rem 1rem 2rem 1rem;
  font-size: 18px;
  text-align: left;
  gap: 0.5rem;
`;
const Overlay = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(83, 89, 35, 0.75);
`;

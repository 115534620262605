import React from "react";

type PageTitleProps = {
  title: string;
  titleColor?: string;
};

const PageTitle = ({ title, titleColor }: PageTitleProps) => {
  return (
    <h1
      style={{
        color: titleColor ? titleColor : "#626060",
        textAlign: "center",
        //   paddingTop: width <= 750 ? "7rem" : "12rem",
      }}
    >
      {title}
    </h1>
  );
};
export default PageTitle;

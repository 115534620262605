import React from "react";
import styled from "@emotion/styled";
import { Avatar } from "@mui/material";
import PageTitle from "../../components/PageTitle";
import { PageWrapper } from "../Home/OurMission";
import Spacer from "../../components/Spacer";
import { colors } from "../../styles/colors";

const Officers = () => {
  return (
    <PageWrapper style={{ backgroundColor: colors.green.light }}>
      <Spacer space={6.5} />
      <PageTitle title="Foundation Officers" />
      <OfficerContainer>
        {OfficersData.map((officer: OfficerType) => (
          <Officer
            photo={officer.photo}
            name={officer.name}
            title={officer.title}
          />
        ))}
      </OfficerContainer>
    </PageWrapper>
  );
};
export default Officers;

const OfficerContainer = styled.div`
  margin: 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  color: #666464;
  background-color: ${colors.green.light};
  padding-bottom: 2rem;
`;

type OfficerType = {
  photo: string;
  name: string;
  title: string;
};
const Officer = ({ photo, name, title }: OfficerType) => {
  return (
    <OfficerWrapper>
      <Avatar src={photo} sx={{ width: 70, height: 70, position: "inherit" }} />
      <Name>{name}</Name>
      <Title>{title}</Title>
    </OfficerWrapper>
  );
};

const OfficerWrapper = styled.div`
  width: fit-content;
  display: flex;
  gap: 1rem;
  align-items: center;
  padding: 2rem;
  border-radius: 5px;
  box-shadow: 0px 0px 7px 0px #000000;
  flex-wrap: wrap;
`;
const Name = styled.div`
  text-align: left;
  font-size: 24px;
  width: 15rem;
`;
const Title = styled.div`
  text-align: left;
  font-size: 24px;
  width: 15rem;
`;

const OfficersData: OfficerType[] = [
  { photo: "", name: "Richard Hansen", title: "President" },
  { photo: "", name: "Jack Simington", title: "Vice President" },
  { photo: "", name: "Angie Copenhaver", title: "Board Member" },
  { photo: "", name: "Tom Fletcher", title: "Board Member/Secretary" },
  { photo: "", name: "Mike Robinson", title: "Board Member" },
  { photo: "", name: "Ryan Weld", title: "Board Member" },
  { photo: "", name: "Walt Smith", title: "Board Member/Treasurer" },
  { photo: "", name: "Ruth Franz", title: "Board Member" },
  { photo: "", name: "Jim Powers", title: "Board Member" },
  { photo: "", name: "Lt. Jason Erickson", title: "Sheriff Liaison to Board" },
];

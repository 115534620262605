import React from "react";
import PageTitle from "../../components/PageTitle";
import Spacer from "../../components/Spacer";
import { PageWrapper } from "../Home/OurMission";

const Grants = () => {
  return (
    <PageWrapper>
      <Spacer space={6.5} />
      <PageTitle title="Grants and Contributions" />
    </PageWrapper>
  );
};
export default Grants;

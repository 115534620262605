import { createTheme } from "@material-ui/core/styles";
import { colors } from "./colors";
// const theme = createTheme({
//   palette: {
//     primary: blue,
//   },
// });

export const defaultTheme = createTheme({
  palette: {
    primary: { main: colors.green.dark },
    secondary: { main: colors.green.normal },
  },
});

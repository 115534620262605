import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Dialog, DialogTitle, Snackbar, TextField } from "@material-ui/core";
import CircularProgress from "@mui/material/CircularProgress";
import { Button } from "@mui/material";
import { storage, firestore } from "../../firebase/firebase.utils";
import { blog } from "./BlogConfig";
import { colors } from "../../styles/colors";
import { AlertProps } from "../AdminDash";
import Alert from "../../components/Alert";
import firebase from "firebase/compat";

type ManageBlogProps = {
  isAdd: boolean;
  open: boolean;
  blogObject: blog;
  setOpen: (open: boolean) => void;
};
const ManageBlog = ({ open, setOpen, blogObject, isAdd }: ManageBlogProps) => {
  const [blogData, setBlogData] = useState<blog>(blogObject);
  const [imageAsFile, setImageAsFile] = useState("");
  const allInputs = { imgUrl: "" };
  const [imageAsUrl, setImageAsUrl] = useState(allInputs);
  const [isFileLoading, setIsFileLoading] = useState<boolean>(false);
  useEffect(() => {
    if (!isAdd) {
      setBlogData({
        id: blogObject.id,
        title: blogObject.title ?? "",
        description: blogObject.description ?? "",
        image: blogObject.image,
      });
    }
  }, [blogObject, isAdd]);

  const [isAlertOpen, setIsAlertOpen] = useState<AlertProps>({
    open: false,
    color: "",
    message: "",
  });

  const firebaseStorage = storage.app.storage();
  const firestoreDb = firestore.app.firestore();
  //@ts-ignore
  const handleImageAsFile = (e) => {
    const image = e.target.files[0];
    setImageAsFile((imageFile) => image);
  };
  //@ts-ignore
  const handleFireBaseUpload = () => {
    //TODO: Turn into a toast
    setIsFileLoading(true);
    // async magic goes here...
    if (imageAsFile === "") {
      //TODO: turn into a toast
    }
    const uploadTask = firebaseStorage
      //@ts-ignore
      .ref(`/images/${imageAsFile.name}`)
      //@ts-ignore
      .put(imageAsFile);
    //initiates the firebase side uploading
    uploadTask.on(
      "state_changed",
      (snapShot: any) => {
        //takes a snap shot of the process as it is happening
      },
      (err: any) => {
        //catches the errors
      },
      () => {
        // gets the functions from storage references the image storage in firebase by the children
        // gets the download url then sets the image from firebase as the value for the imgUrl key:
        firebaseStorage
          .ref("images")
          //@ts-ignore
          .child(imageAsFile.name)
          .getDownloadURL()
          .then((fireBaseUrl: any) => {
            setImageAsUrl((prevObject) => ({
              ...prevObject,
              imgUrl: fireBaseUrl,
            }));
            setBlogData({ ...blogData, image: fireBaseUrl });
            setIsFileLoading(false);
          });
      }
    );
  };

  async function createNewPost() {
    try {
      const docRef = await firestoreDb.collection("blogs").add({
        title: blogData.title,
        description: blogData.description,
        image: blogData.image,
        date: firebase.firestore.FieldValue.serverTimestamp(),
      });
      setIsAlertOpen({
        open: true,
        message: `Successfully posted ${docRef.id}`,
        color: colors.green.normal,
      });
      setBlogData({ title: "", description: "", image: "" });
      setOpen(false);
    } catch (e) {
      console.error("Error adding document: ", e);
      setIsAlertOpen({
        open: true,
        message: `Error in Creating blog ${e}}`,
        color: colors.green.normal,
      });
    }
  }

  async function updatePost() {
    try {
      const docRef = await firestoreDb
        .collection("blogs")
        .doc(blogData.id)
        .set(blogData);
      setIsAlertOpen({
        open: true,
        message: `Successfully updated ${blogData.id}`,
        color: colors.green.normal,
      });
      setBlogData({ title: "", description: "", image: "" });
    } catch (e) {
      setIsAlertOpen({
        open: true,
        message: `An error occurred while trying to update ${blogData.id}`,
        color: colors.red.normal,
      });
    }
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
          setBlogData({ title: "", description: "", image: "" });
        }}
      >
        <DialogTitle>
          {isAdd ? "Add a new blog" : "Edit a new blog"}
        </DialogTitle>
        <DashboardContainer>
          {isFileLoading ? (
            <CircularProgress style={{ alignSelf: "center" }} color="success" />
          ) : (
            <>
              <TextField
                label="Title"
                value={blogData?.title}
                onChange={(e) =>
                  setBlogData({ ...blogData, title: e.target.value })
                }
              />
              <TextField
                label="Description"
                value={blogData?.description}
                multiline
                onChange={(e) =>
                  setBlogData((prevState) => {
                    return {
                      ...prevState,
                      description: e.target.value,
                    };
                  })
                }
              />
              <UploadContainer>
                {/* TODO: Need to fix this so after upload we show file name. */}
                {imageAsUrl ? (
                  <>
                    <input type="file" onChange={handleImageAsFile} />
                    <Button
                      variant="outlined"
                      onClick={() => {
                        handleFireBaseUpload();
                      }}
                    >
                      Upload Picture
                    </Button>
                  </>
                ) : (
                  <div>{imageAsFile}</div>
                )}
              </UploadContainer>
              <Button
                variant="outlined"
                onClick={() => {
                  isAdd ? createNewPost() : updatePost();
                }}
              >
                Submit Post
              </Button>
            </>
          )}
        </DashboardContainer>
      </Dialog>
      <Snackbar
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        open={isAlertOpen.open}
        autoHideDuration={3000}
        onClose={() =>
          setIsAlertOpen({
            open: false,
            color: "",
            message: "",
          })
        }
      >
        <Alert message={isAlertOpen.message} color={isAlertOpen.color} />
      </Snackbar>
    </>
  );
};
export default ManageBlog;

const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 30rem;
  box-shadow: 0px 0px 7px 0px #000000;
  border-radius: 5px;
  padding: 2rem 5rem 2rem 5rem;
  align-self: center;
`;
const UploadContainer = styled.div`
  display: flex;
  align-items: center;
  border: solid 1px ${colors.green.dark};
  border-radius: 5px;
  padding: 1rem;
`;

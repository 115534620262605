import React from "react";
import TextContainer from "../../components/TextContainer";
import pink from "../../resources/benton_pink_20222.jpg";
import autismPatch from "../../resources/autismPatch.jpg";
import pinkPhoto from "../../resources/image_50802177.jpg";
import { colors } from "../../styles/colors";

import { DonationContainer, goToDonationPage } from "../Donations";
import PageTitle from "../../components/PageTitle";
import Spacer from "../../components/Spacer";
import { PageWrapper } from "./OurMission";

const CurrentInitiatives = () => {
  return (
    <PageWrapper
      style={{ backgroundColor: colors.green.light, height: "100%" }}
    >
      <Spacer space={6.5} />
      <PageTitle title="Current Initiatives" />
      <div style={{ backgroundColor: colors.green.light }}>
        <TextContainer
          title="Autism Awareness Month"
          description={`You may not be aware, but April is Autism Awareness Month. To show our support, the Benton County Sheriff’s Office, with the help of the Benton County Sheriff Foundation, has commissioned the design of the first ever BCSO Autism Awareness Patch. Together we want to bring awareness and support for local non-profits who work with autistic members of our community.
        
        Through the month of April, we will raise funds for Autism Awareness Month. The sheriff’s office has selected to recognize the local non-profit Spectrum Studios. Their mission is, “to provide employment, on the job training, and growth opportunities to young adults on the autism spectrum.”
        
        The first 100 people who donate a minimum of $10 to our Autism Awareness fundraiser, will receive a commemorative BCSO Autism Awareness Patch. There are only 100 patches so hurry and make your donation.
        
        On May 2, 2022 BCSO and the foundation will make a donation to Spectrum Studios.`}
          // children={
          // <DonationContainer
          //   style={{ padding: ".5rem" }}
          //   color={colors.green.normal}
          //   hoverColor={colors.green.dark}
          //   onClick={() =>
          //     goToDonationPage(
          //       "https://www.paypal.com/donate/?hosted_button_id=G9T95ACLL99DG"
          //     )
          //   }
          // >
          //   <span>Donate Now!</span>
          //   <img src={autismPatch} height="20px" width="20px" alt="" />
          // </DonationContainer>
          // }
        />
        <TextContainer
          title="Pink Patch Fundraiser"
          description="The Benton County Sheriff’s Office is partnering with the Tri-City Cancer Center Foundation and the Benton County Sheriff Foundation in raising money to combat breast cancer. This project came about because of Deputy Cody Roberts, Corporal Mike McDermott, and Sheriff Tom Croskrey. They worked together to design a pink patch to bring awareness and support in the fight against breast cancer. Anyone who donates $10 or more to this cause will receive a commemorative pink patch for every $10 they donate. The Benton County Sheriff’s Office is excited to work with the Tri-City Cancer Center Foundation."
          photos={
            <>
              <img src={pinkPhoto} alt="" height="50%" width="50%" />
            </>
          }
        />
      </div>
    </PageWrapper>
  );
};
export default CurrentInitiatives;

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import "firebase/compat/storage";

const config = {
  apiKey: "AIzaSyDi4Y2VEeaZUZrwmvoZWJmjaqgBZ5y3Aq4",
  authDomain: "gobcsf.firebaseapp.com",
  projectId: "gobcsf",
  storageBucket: "gobcsf.appspot.com",
  messagingSenderId: "378823184592",
  appId: "1:378823184592:web:603dabaf72981ed7a1ced5",
  measurementId: "G-4HT0Z4LGEN",
};

firebase.initializeApp(config);

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const storage = firebase.storage();

export default firebase;

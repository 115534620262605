import React from "react";
import PageTitle from "../../components/PageTitle";
import Spacer from "../../components/Spacer";
import TextContainer from "../../components/TextContainer";
import { PageWrapper } from "./OurMission";
import { colors } from "../../styles/colors";
const OurPurpose = () => {
  const description = `To strengthen the services of the Benton County Sheriff’s Office, promote excellence within the department, and improve public safety for Benton County.`;
  return (
    <PageWrapper
      style={{ backgroundColor: colors.green.light, height: "100%" }}
    >
      <Spacer space={6.5} />
      <PageTitle title="Benton Country Sheriff Foundation Washington State" />
      <TextContainer title="Our Purpose" description={description} />
    </PageWrapper>
  );
};
export default OurPurpose;

export const colors = {
  green: {
    dark: "#535923",
    normal: "#6B732F",
    light: "#EFF2D5",
  },
  white: {
    dark: "#F2F2F2",
  },
  red: {
    normal: "#A71D0A",
  },
  pink: {
    normal: "#FF83A6",
    dark: "#f0225c",
  },
};

import React, { useState } from "react";
import styled from "@emotion/styled";
import { TextField } from "@material-ui/core";
import { Button } from "@mui/material";
import { auth } from "../firebase/firebase.utils";

const SignIn = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const authentication = auth.app.auth();

  const handleUserClick = () => {
    authentication
      .signInWithEmailAndPassword(email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        //TODO: Turn into a snackbar
        // alert(`Successfully logged in ${user?.displayName}`);
        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error(errorCode, errorMessage);
        //TODO: Turn into a red error message around container.
        // alert("An Error occurred.");
      });
  };

  return (
    <div style={{ width: "100%" }}>
      <SignInContainer>
        <TextField
          style={{ width: "70%" }}
          type="email"
          value={email}
          onChange={(event) => {
            setEmail(event.target.value);
          }}
          label="Email:"
        />
        <TextField
          style={{ width: "70%" }}
          type="password"
          value={password}
          onChange={(event) => {
            setPassword(event.target.value);
          }}
          label="Password:"
        />
        <Button
          style={{ width: "10rem", alignSelf: "flex-end" }}
          color="primary"
          onClick={() => {
            handleUserClick();
          }}
          variant="outlined"
        >
          Sign In
        </Button>
      </SignInContainer>
    </div>
  );
};
export default SignIn;

const SignInContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1.5rem;
  padding: 1rem;
  gap: 1.5rem;
  min-width: 225px;
  box-shadow: 0px 0px 7px 0px #000000;
  border-radius: 5px;
`;

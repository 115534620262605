import React, { useState } from "react";
import useWindowDsimensions from "../customHooks/UseWindowDimensions";
import { colors } from "../styles/colors";

const DonateButton = () => {
  const [hoveredStyle, setHoveredStyle] = useState<boolean>(false);
  const getHoverColor = () => {
    return hoveredStyle ? colors.green.normal : colors.green.dark;
  };

  return (
    <a
      style={{
        color: colors.white.dark,
        backgroundColor: getHoverColor(),
        borderRadius: "5px",
        padding: ".5rem 2rem .5rem 2rem",
        textDecoration: "none",
        fontSize: "24px",
      }}
      onMouseEnter={() => {
        setHoveredStyle(true);
      }}
      onMouseLeave={() => {
        setHoveredStyle(false);
      }}
      href="https://www.paypal.com/donate/?hosted_button_id=C8M9SVBNDPPVL"
      target="_blank"
      rel="noreferrer"
    >
      Donate
    </a>
  );
};
export default DonateButton;

import React, { useEffect, useState } from "react";
import { blog } from "./BlogConfig";
import styled from "@emotion/styled";
import useWindowDimensions from "../../customHooks/UseWindowDimensions";
import { colors } from "../../styles/colors";
import { firestore } from "../../firebase/firebase.utils";
import PageTitle from "../../components/PageTitle";
import { PageWrapper } from "../Home/OurMission";
import Spacer from "../../components/Spacer";

const Blog = () => {
  const { width } = useWindowDimensions();
  const [blogData, setBlogData] = useState<blog[]>([]);
  useEffect(() => {
    firestore
      .collection("blogs")
      .orderBy("date", "desc")
      .onSnapshot((snapshot) => {
        setBlogData(
          //@ts-ignore
          snapshot?.docs?.map((doc) => {
            return { id: doc.id, ...doc.data() };
          })
        );
      });
  }, []);

  return (
    <PageWrapper style={{ backgroundColor: colors.green.light }}>
      <Spacer space={6.5} />
      <PageTitle title="Check out the latest!" />
      <BlogContainer width={width}>
        {blogData.map((post, index) => {
          return (
            <BlogPost width={width} key={post.id}>
              <h1>{post.title}</h1>
              {post.image && (
                <img
                  style={{
                    width: width < 750 ? "70%" : "70%",
                    height: width < 750 ? "50%" : "70%",
                  }}
                  src={post.image}
                  alt={`post ${index}`}
                />
              )}
              <StyledParagraph>{post.description}</StyledParagraph>
              {post.link && (
                <StyledAnchor href={post.link} target="_blank" rel="noreferrer">
                  Read More
                </StyledAnchor>
              )}
            </BlogPost>
          );
        })}
      </BlogContainer>
    </PageWrapper>
  );
};
export default Blog;

// Blog Styles
type ResponsiveProps = {
  width: Number;
};
const BlogContainer = styled("div")<ResponsiveProps>`
  display: flex;
  background-color: ${colors.green.light};
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: ${(props) =>
    props.width > 750 ? "0 15rem 3rem 15rem" : "0 2rem 3rem 2rem"};
`;
const BlogPost = styled("div")<ResponsiveProps>`
  width: 100%;
  padding: 1rem;
  color: #626060;
  border: 2px solid #9c9898;
  border-radius: 5px;
  text-align: center;
  overflow-y: scroll;
`;
const StyledParagraph = styled.p`
  font-size: large;
`;
const StyledAnchor = styled.a`
  width: 1rem;
  height: 0.5rem;
  background-color: ${colors.green.dark};
  text-decoration: none;
  padding: 0.5rem;
  color: ${colors.white.dark};
  border-radius: 5px;
`;

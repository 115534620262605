import styled from "@emotion/styled";
import React from "react";
import PageTitle from "../../components/PageTitle";
import Spacer from "../../components/Spacer";
import { colors } from "../../styles/colors";
import { PageWrapper } from "../Home/OurMission";

const About = () => {
  return (
    <PageWrapper style={{ backgroundColor: colors.green.light }}>
      <Spacer space={6.5} />
      <PageTitle title="About the Foundation" />
      <div style={{ backgroundColor: colors.green.light }}>
        <SectionContainer>
          <SectionParagraphTitle>About the Foundation</SectionParagraphTitle>
          <SectionParagraph>
            The Benton County Sheriff Foundation, founded in October 2018, is a
            (501)(c)(3) non-profit corporation dedicated to aiding the Benton
            County Sheriff Office and the community. With the donations of funds
            and other resources, the Foundation supports the Benton County
            Sheriff Office by promoting excellence within the Sheriff’s office,
            improving public safety and community relationships within Benton
            County, and serving those in need by providing resources not
            otherwise available through traditional sources.
          </SectionParagraph>
          <SectionParagraphTitle>Why Police Foundations</SectionParagraphTitle>
          <SectionParagraph>
            Police foundations have a long history within the United States,
            dating back to the 1970’s, as a major source of private support,
            sharing in the vision that communities are made safer and better
            places to live through the dedication and hard work of police
            officers.
          </SectionParagraph>
          <SectionParagraph>
            Generally, police foundations serve local law enforcement by
            fundraising, granting tax-exempt gifts, individual donations, and
            volunteer support to improve the effectiveness of law enforcement
            activities. To effectively serve the community, local law
            enforcement needs to keep pace with evolving technology, be able
            evaluate and invest in new equipment, and invest in professional
            development.
          </SectionParagraph>
          <SectionParagraphTitle>
            Benton County Sheriff Foundation
          </SectionParagraphTitle>
          <SectionParagraph>
            Within Benton County, the Benton County Sheriff Office, with the
            financial and volunteer support of the Benton County Sheriff
            Foundation, has been able to:
            <ul>
              <li>
                Provide food and gifts to families in need during the holidays.
              </li>
              <li>Help with school supplies and clothes for children.</li>
              <li>
                Provide basic life essentials to families struggling
                financially.
              </li>
              <li>
                Provide roadside assistance to stranded travelers, (e.g., food,
                lodging or car repair).
              </li>
              <li>
                Install smoke detectors in mobile homes that did not have them.
              </li>
              <li>
                Provide K9 training and equipment for patrol and narcotics
                units.
              </li>
              <li>
                Equip the water patrol unit with life vests, primarily for
                families that did not have correct sizes, and assist with boater
                education and annual boat inspections.
              </li>
              <li>
                Support deputy and office staff recognition for outstanding
                performance.
              </li>
            </ul>
          </SectionParagraph>
          <SectionParagraphTitle>Currently</SectionParagraphTitle>
          <SectionParagraph>
            In a relatively short period of time the Tri-Cities and Benton
            County have witnessed significant growth, bringing with it a new set
            of needs and challenges, and stretching existing budgets for public
            services including budgets for law enforcement. As such, the Benton
            County Sheriff Foundation, even though relatively new, will have an
            expanding role supporting the Benton County Sheriff Office on
            activities and projects designed to create a safer Benton County for
            new and existing families to live, work and play.
          </SectionParagraph>
          <SectionParagraphTitle>Mailing Address</SectionParagraphTitle>
          <SectionParagraph>
            8220 W Gage Blvd, #176 Kennewick, WA 99336
          </SectionParagraph>
        </SectionContainer>
      </div>
    </PageWrapper>
  );
};
export default About;

const SectionContainer = styled.div`
  color: #666464;
  margin: 1rem;
  display: flex;
  flex-direction: column;
  border: 1px solid ${colors.green.dark};
  border-radius: 5px;
  box-shadow: 0px 0px 7px 0px #000000;
  padding: 1rem 5rem 2rem 5rem;
  align-items: flex-start;
  background-color: ${colors.green.light};
`;
const SectionParagraphTitle = styled.h3`
  font-weight: bold;
`;
const SectionParagraph = styled.div`
  text-align: left;
`;
